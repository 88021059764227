import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import Loadable from "react-loadable";
import { graphql } from "gatsby";
import Layout from "../templates/layout";
import "../assets/scss/homepage.scss";

import LoadService from "../components/LandingPage/Service";
import LoadTeam from "../components/LandingPage/Team";
import LoadFeatures from "../components/LandingPage/Features";
import LoadPortfolio from "../components/LandingPage/Portfolio";
import LoadModal from "../components/LandingPage/ModalVideo";
import LoadAbout from "../components/LandingPage/About";
import LoadCarousel from "../components/LandingPage/Carousel";

// const loader = () => <div></div>;

// const LoadService = Loadable({
//   loader: () => import("../components/LandingPage/Service"),
//   loading: loader,
// });

// const LoadTeam = Loadable({
//   loader: () => import("../components/LandingPage/Team"),
//   loading: loader,
// });

// const LoadFeatures = Loadable({
//   loader: () => import("../components/LandingPage/Features"),
//   loading: loader,
// });

// const LoadPortfolio = Loadable({
//   loader: () => import("../components/LandingPage/Portfolio"),
//   loading: loader,
// });

// const LoadModal = Loadable({
//   loader: () => import("../components/LandingPage/ModalVideo"),
//   loading: loader,
// });

// const LoadAbout = Loadable({
//   loader: () => import("../components/LandingPage/About"),
//   loading: loader,
// });

// const LoadCarousel = Loadable({
//   loader: () => import("../components/LandingPage/Carousel"),
//   loading: loader,
// });

const Page = () => {
  const { t } = useTranslation();
  const [isVideoLoad, setIsVideoLoad] = useState(false);

  return (
    <>
      <Helmet>
        <title>{`${t("meta.homepage.title")} | ${t("meta.title")}`}</title>
        <meta content={t("meta.homepage.keyword")} name="keywords" />
        <meta content={t("meta.homepage.desc")} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout currentLink="" description={t("meta.homepage.desc")}>
        <>
          <LoadCarousel />
          {/* <ContactInfo /> */}
          <LoadAbout
            onPlay={() => {
              setIsVideoLoad(true);
            }}
          />

          <LoadModal isShow={isVideoLoad} />
          <LoadService />
          <LoadFeatures />
          <LoadPortfolio />
          <LoadTeam />

          {/* <Testimony /> */}
        </>
      </Layout>
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
