import React from "react";
import ComponentWow from "../../Wow";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { Carousel as CarouselConstants } from "../../../constants";

const Carousel = () => {
  const { t } = useTranslation();

  const sliders = [
    {
      title: t("homepage.carousel.slider_1.title"),
      desc: t("homepage.carousel.slider_1.desc"),
    },
    {
      title: t("homepage.carousel.slider_2.title"),
      desc: t("homepage.carousel.slider_2.desc"),
    },
    {
      title: t("homepage.carousel.slider_3.title"),
      desc: t("homepage.carousel.slider_3.desc"),
    },
  ];

  return (
    <ComponentWow>
      <div className="container-fluid p-0">
        {/* <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#header-carousel"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#header-carousel" data-slide-to="1"></li>
            <li data-target="#header-carousel" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="img-fluid w-100"
                src={CarouselConstants[0].src}
                alt={CarouselConstants[0].alt}
                title={CarouselConstants[0].title}
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                >
                  <h5 className="text-primary text-uppercase mb-md-3">
                    {sliders[0].title}
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    {sliders[0].desc}
                  </h1>
                  <a
                    href=""
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#getAQuote"
                  >
                    {t("getAQuote")}
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="img-fluid w-100"
                src={CarouselConstants[1].src}
                alt={CarouselConstants[1].alt}
                title={CarouselConstants[1].title}
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                  style={{
                    width: "100%",
                    maxWidth: "900px",
                  }}
                >
                  <h5 className="text-primary text-uppercase mb-md-3">
                    {sliders[1].title}
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    {sliders[1].desc}
                  </h1>
                  <a
                    href=""
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#getAQuote"
                  >
                    {t("getAQuote")}
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="img-fluid w-100"
                src={CarouselConstants[2].src}
                alt={CarouselConstants[2].alt}
                title={CarouselConstants[2].title}
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div
                  className="p-5"
                  style={{
                    width: "100%",
                    maxWidth: "900px",
                  }}
                >
                  <h5 className="text-primary text-uppercase mb-md-3">
                    {sliders[2].title}
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    {sliders[2].desc}
                  </h1>
                  <a
                    href=""
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#getAQuote"
                  >
                    {t("getAQuote")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12 carousel-on-mobile">
              <img
                className="img-fluid w-100"
                src={CarouselConstants[0].src}
                alt={CarouselConstants[0].alt}
                title={CarouselConstants[0].title}
              />
              <div className="carousel-caption d-flex align-items-center justify-content-center">
                <div className="p-5 inner-carousel-text">
                  <h5 className="text-primary text-uppercase mb-md-3">
                    {sliders[0].title}
                  </h5>
                  <h1 className="display-3 text-white mb-md-4">
                    {sliders[0].desc}
                  </h1>
                  <a
                    href=""
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#getAQuote"
                  >
                    {t("getAQuote")}
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default Carousel;
