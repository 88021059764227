import React from "react";
import ComponentWow from "../../Wow"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Features as FeaturesConstants } from "../../../constants";

const Features = () => {
  const { t } = useTranslation();
  return (
    <ComponentWow>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 pt-lg-5 pb-3">
              <h6 className="text-primary font-weight-semi-bold text-uppercase mb-3">
                {t("homepage.features.whyChooseUs")}
              </h6>
              <h3 className="h1 mb-4 section-title">
                {t("homepage.features.title")}
              </h3>
              <p className="mb-4">{t("homepage.features.desc")}</p>
            </div>
            <div
              className="col-lg-5"
              style={{
                minHeight: "400px",
              }}
            >
              <div className="position-relative h-100 rounded overflow-hidden d-flex align-items-center justify-content-center">
                <img
                  className="position-absolute w-100 Sirv"
                  data-src={FeaturesConstants.src}
                  alt={FeaturesConstants.alt}
                  title={FeaturesConstants.title}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default Features;
