import React from "react";
import ComponentWow from "../../Wow";
// import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ListOfProducts } from "../../../constants";

const Service = () => {
  const { t } = useTranslation();

  const renderLists = () => {
    return ListOfProducts.map((val, index) => {
      const productName = t(`dataProducts.${val.data.link}.name`);

      return (
        <div
          className="d-flex flex-column align-items-center text-center bg-white rounded"
          key={`service-${index}`}
          style={{
            minHeight: "350px",
          }}
        >
          <img
            className="Sirv"
            data-src={val.src}
            alt={val.alt}
            title={val.title ||productName}
          />
          <h5 className="font-weight-bold mt-4 pb-2">{productName}</h5>
        </div>
      );
    });
  };
  return (
    <ComponentWow>
      <div className="container-fluid bg-service py-5">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h6 className="font-weight-semi-bold text-uppercase mb-3 text-primary">
                {t("homepage.service.ourProduct")}
              </h6>
              <h3 className="h1 mb-4 section-title text-white">
                {t("homepage.service.title")}
              </h3>
              <p className="text-white">{t("homepage.service.desc")}</p>
              <a
                href=""
                className="btn btn-primary mt-3 py-2 px-4"
                data-toggle="modal"
                data-target="#getAQuote"
              >
                {t("getAQuote")}
              </a>
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0">
              <div className="owl-service owl-carousel service-carousel position-relative owl-theme">
                {renderLists()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default Service;
